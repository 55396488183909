@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'), url('../fonts/Muller-Regular.woff') format('woff'), url('../fonts/Muller-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Muller';
    src: local('Muller Bold'), local('Muller-Bold'), url('../fonts/MullerBold.woff') format('woff'), url('../fonts/MullerBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
	font-family: "MyriadPro";
	src: url("../fonts/MyriadProBold.eot");
	src: url("../fonts/MyriadProBold.eot?#iefix")format("embedded-opentype"),
	url("../fonts/MyriadProBold.woff") format("woff"),
	url("../fonts/MyriadProBold.ttf") format("truetype");
	font-style: normal;
	font-weight: 600;
}
