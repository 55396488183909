.header {
  font-size: 14px;
  &__link {
    color: #404040;
    text-decoration: none;
  }
}
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
  @media (max-width: 1280px) {
    padding: 8px 10px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
  &__left {
    color: #bd2464;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__center {
    text-transform: uppercase;
    font-weight: 700;
    @media (max-width: 1024px) {
      margin-right: 37px;
    }
  }
  &__right {
    width: 300px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.header-bottom {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    justify-content: space-between;
    margin-bottom: 25px;
  }
}

.header-tablet {
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    flex-grow: 1;
    font-size: 12px;
  }
  @media (max-width: 767px) {
    height: 25px;
    width: 100%;
    box-sizing: border-box;
    background-color: #f2f2f2;
    padding-top: 8px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 44px;
    display: block;
    text-align: center;
  }
  .header-top__center {
    margin: 0 auto;
  }
  .phone {
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.logo {
  background-color: #bd2464;
  width: 245px;
  min-width: 200px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    background: none;
    height: auto;
    width: auto;
    min-width: 0;
    margin-left: 17px;
    margin-right: 33px;
  }
  @media (max-width: 767px) {
    margin-left: 2px;
    flex-grow: 1;
    justify-content: flex-start;
  }
  &__img {
    &--desc {
      @media (max-width: 1024px) {
        display: none;
      }
    }
    &--tablet {
      display: none;
      @media (max-width: 1024px) {
        display: block;
      }
    }
    &--mobile {
      display: none;
    }
  }
}

.nav-icon {
  width: 46px;
  height: 44px;
  background-color: #bd2464;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: flex;
    z-index: 200;
  }
  @media (max-width: 767px) {
    background-color: transparent;
  }
  &__line {
    background-color: #fff;
    width: 23px;
    height: 2px;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      background-color: #a7a7a7;
      width: 15px;
      height: 1px;
      margin-bottom: 4px;
    }
  }
}

.nav {
  margin-left: 100px;
  flex-grow: 1;
  @media (max-width: 1180px) {
    margin-left: 20px;
  }
  @media (max-width: 1024px) {
    opacity: 0;
    visibility: hidden;
    transform-origin: left top;
	  transform: scale(0);
    transition: all 0.4s ease-in-out;
    position: fixed;
    background-color: #f5f5f5;
    margin-left: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.active {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  &__list {
    padding: 0;
    list-style: none;
    display: flex;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  &__item {
    margin-right: 25px;
    @media (max-width: 1024px) {
      margin-bottom: 30px;
      text-align: center;
    }
  }
  &__link {
    color: #404040;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      color: #bd2464;
    }
  }
}


.search {
  display: flex;
  margin-right: 20px;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
  &__input {
    width: 204px;
    border: none;
    background-color: #ffffff;
    color: #404040;
    font-size: 14px;
    padding: 0 24px;
    box-sizing: border-box;
    @media (max-width: 1180px) {
      width: 140px;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &__btn {
    width: 46px;
    height: 44px;
    background-color: #bd2464;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-image: url(/images/sprite.svg#search-white);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    @media (max-width: 1024px) {
      background: none;
      height: auto;
      &:before {
        background-image: url(/images/sprite.svg#search-gray);
      }
    }
    @media (max-width: 767px) {
      width: 12px;
      height: 12px;
      margin-right: 18px;
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.cart-block {
  display: flex;
}

.heart {
  background-color: #ffffff;
  width: 45px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: '';
    display: block;
    width: 19px;
    height: 16px;
    background-image: url(/images/sprite.svg#heart);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media (max-width: 1180px) {
    display: none;
  }
}

.cart {
  background-color: #bd2464;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 12px;
  &:before {
    content: '';
    display: block;
    margin-right: 5px;
    width: 15px;
    height: 14px;
    background-image: url(/images/sprite.svg#shopping-cart);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media (max-width: 1024px) {
    height: 44px;
    box-sizing: border-box;
  }
  span {
    white-space: nowrap;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.phone-btn {
  width: 12px;
  height: 12px;
  background-image: url(/images/sprite.svg#phone-gray);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 17px;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
