
.no-scroll {
  overflow: hidden;
}

.arrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 15px;
  left: 0;
  padding: 0 28px 0 50px;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 767px) {
    justify-content: center;
  }
  .dots {
    .slick-dots	{
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
      li {
        display: flex;
        align-items: center;
        margin-right: 48px;
        width: 7px;
        height: 7px;
        background-color: #ffffff;
        border-radius: 50%;
        opacity: 0.4;
        @media (max-width: 767px) {
          margin-right: 29px;
          width: 6px;
          height: 6px;
        }
        &:last-child {
          margin-right: 0;
        }
        &.slick-active {
          opacity: 1;
        }
      }
      button {
        border: none;
        font-size: 0;
        line-height: 0;
        padding: 0;
      }
    }
  }
}
.arrow {
  width: 16px;
  height: 11px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
  &__prev {
    background-image: url(/images/sprite.svg#arrow-white_left);
  }
  &__next {
    background-image: url(/images/sprite.svg#arrow-white_right);
  }
}
