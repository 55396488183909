@import 'fonts.scss';
@import 'var.scss';
@import 'general.scss';
@import 'header.scss';
@import 'footer.scss';

::placeholder {
	opacity: 1;
	font-family: 'Muller', sans-serif;
}

body {
	margin: 0;
	padding: 0;
	background-color: #f5f5f5;
	font-family: 'Muller', sans-serif;
	color: #404040;
}

.container {
	max-width: 1260px;
	margin: 0 auto;
}


.main {
	height: 430px;
	display: flex;
	margin-bottom: 24px;
	@media (max-width: 1024px) {
		height: 277px;
	}
	@media (max-width: 767px) {
		display: block;
		height: auto;
	}
	&-left {
		height: 100%;
		background-color: #ffffff;
		box-shadow: 0 8px 115px 2px rgba(40, 39, 48, 0.11);
		width: 50%;
		padding: 42px 57px 40px 240px;
		box-sizing: border-box;
		background-image: url(/images/main-photo.png);
		background-repeat: no-repeat;
		background-position: left 47px bottom;
		@media (max-width: 1220px) {
			background-position: left bottom;
			padding: 42px 57px 40px 180px;
		}
		@media (max-width: 1180px) {
			padding: 42px 20px 40px 165px;
		}
		@media (max-width: 1024px) {
			background-image: url(/images/main-photo--tablet.png);
			background-position: left 20px bottom;
			padding: 20px 50px 40px 132px;
		}
		@media (max-width: 767px) {
			width: 100%;
			padding: 20px 10px 40px 110px;
			background-position: left bottom;
		}
	}
	&-right {
		height: 100%;
		width: 50%;
		background-color: #bd2464;
		padding: 42px 28px 40px 50px;
		box-sizing: border-box;
		background-image: url(/images/main-right_bg.png);
		background-repeat: no-repeat;
		background-position: right bottom;
		position: relative;
		@media (max-width: 1260px) {
			padding: 42px 15px 40px 20px;
		}
		@media (max-width: 1024px) {
			padding: 28px 15px 28px 33px;
		}
		@media (max-width: 767px) {
			width: 100%;
			padding: 24px 0px 20px 10px;
			height: 277px;
		}
	}
	&__title {
		margin-top: 0;
		font-size: 30px;
		font-weight: 600;
		padding-left: 21px;
		@media(max-width: 1024px) {
			font-size: 19px;
		}
		@media (max-width: 767px) {
			padding-left: 15px;
		}
	}
	&__autor {
		color: #bd2464;
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 11px;
		display: block;
		padding-left: 21px;
		@media(max-width: 1024px) {
			font-size: 12px;
			margin-bottom: 1px;
		}
		@media (max-width: 767px) {
			padding-left: 15px;
		}
	}
	&__subtitle {
		color: #555555;
		font-size: 12px;
		padding-left: 21px;
		display: block;
		@media(max-width: 1024px) {
			font-size: 8px;
		}
		@media (max-width: 767px) {
			padding-left: 15px;
		}
	}
}

.quote {
	padding: 3px 21px;
	position: relative;
	font-size: 12px;
	line-height: 18px;
	color: #555555;
	margin-bottom: 30px;
	@media (max-width: 1024px) {
		font-size: 10px;
		line-height: normal;
		margin-bottom: 10px;
	}
	@media (max-width: 767px) {
		padding: 3px 15px;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 16px;
		height: 14px;
		background-image: url(/images/sprite.svg#quote);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		@media (max-width: 767px) {
			width: 11px;
			height: 11px;
		}
	}
	&__text {
		display: block;
		margin-bottom: 20px;
		position: relative;
		height: 109px;
		overflow: hidden;
		@media (max-width: 1024px) {
			height: 66px;
			margin-bottom: 2px;
		}
	}
	&__base {
		display: block;
		width: 100%;
		height: 40px;
		background-image: linear-gradient(to top, #fff, transparent);
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

.link {
	color: #bd2464;
	font-size: 14px;
	text-decoration: none;
	padding-bottom: 1px;
	background-image: url(/images/link_bg.png);
	background-repeat: repeat-x;
	background-position: bottom;
	@media (max-width: 1024px) {
		font-size: 10px;
	}
	&:hover {
		background: none;
	}
}

.main-slider {
	color: #fff;
	height: 100%;
	&__wrap {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	&__item {
		position: relative;
		background-repeat: no-repeat;
		background-position: right center;
		width: 100%;
		display: none;
		&:first-child {
			display: block;
		}
	}
	&__text {
		height: 330px;
		width:245px;
		overflow: hidden;
		position: relative;
		padding-bottom: 65px;
		box-sizing: border-box;
		@media (max-width: 1024px) {
			height: 210px;
			width: 158px;
			min-width: 158px;
		}
		ul {
			padding: 0;
			list-style: none;
			margin-bottom: 30px;
			max-width: 184px;
		}
		li {
			font-size: 14px;
			margin-bottom: 12px;
			position: relative;
			padding-left: 20px;
			&:before {
				content: '';
				width: 6px;
				height: 6px;
				background-image: url(/images/sprite.svg#corner-white);
				background-position: center;
				background-size: cover;
				display: block;
				position: absolute;
				left: 0;
				top: 3px;
			}
			@media (max-width: 1024px) {
				font-size: 10px;
				margin-bottom: 11px;
			}
			@media (max-width: 767px) {
				margin-bottom: 8px;
			}
		}
	}
	&__title {
		color: #ffffff;
		font-size: 48px;
		line-height: 48px;
		font-weight: 600;
		margin-bottom: 21px;
		margin-top: 0;
		max-width: 184px;
		@media (max-width: 1180px) {
			font-size: 40px;
			line-height: 40px;
		}
		@media(max-width: 1024px) {
			font-size: 31px;
			line-height: 31px;
			margin-bottom: 16px;
		}
		@media (max-width: 767px) {
			margin-bottom: 14px;
		}
	}
	&__price {
		font-size: 30px;
		font-weight: 600;
		color: #261e1c;
		width: 245px;
		height: 60px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 20px;
		background-image: url(/images/price_bg.png);
		background-repeat: no-repeat;
		background-position: left bottom;
		position: absolute;
		bottom: 0;
		@media(max-width: 1180px) {
			font-size: 19px;
			width: 158px;
			height: 39px;
			background-size: contain;
		}
	}
	&__img {
		@media (max-width: 1024px) {
			img {
				width: 198px;
			}
		}
	}
}








.goods {
	margin-bottom: 34px;
	display: flex;
	@media (max-width: 1024px) {
		padding: 0 10px;
	}
	@media (max-width: 767px) {
		flex-wrap: wrap;
		padding: 0;
	}
	&__item {
		background-color: #fafafa;
		position: relative;
		width: 25%;
		padding: 29px 30px 56px 29px;
		box-sizing: border-box;
		@media (max-width: 1024px) {
			padding: 18px;
		}
		@media (max-width: 767px) {
			width: 50%;
			padding: 5px 10px;
		}
		&:hover {
			background-color: #ffffff;
			box-shadow: 0 8px 53px 1px rgba(189, 36, 100, 0.11);
			.goods__btn {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&__title {
		font-size: 24px;
		font-weight: 400;
		width: 50%;
		@media (max-width: 1024px) {
			font-size: 15px;
		}
		@media (max-width: 767px) {
			font-size: 13px;
		}
	}
	&__btn {
		width: 70px;
		height: 70px;
		background-color: #bd2464;
		position: absolute;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		visibility: hidden;
		opacity: 0;
		&:before {
			content: '';
			width: 16px;
			height: 12px;
			background-image: url(/images/sprite.svg#arrow-white_right);
			background-repeat: no-repeat;
			background-size: cover;
			display: block;
			@media (max-width: 1024px) {
				width: 10px;
				height: 7px;
			}
		}
		@media (max-width: 1024px) {
			width: 43px;
			height: 43px;
		}
	}
	&__img {
		width: 100%;
		height: 230px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			@media (max-width: 1024px) {
				max-width: 100px;
			}
		}
	}
}



.advantages {
	display: flex;
	justify-content: space-between;
	margin-bottom: 35px;
	@media (max-width: 1024px) {
		flex-wrap: wrap;
		justify-content: flex-start;
		padding: 0 10px;
	}
	&__item {
		width: 227px;
		height: 132px;
		box-sizing: border-box;
		margin-right: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 30px;
		padding-right: 15px;
		font-size: 16px;
		background-image: url(/images/mission-block.png);
		background-size: cover;
		text-decoration: none;
		color: #404040;
		@media (max-width: 1024px) {
			margin-bottom: 23px;
		}
		@media (max-width: 767px) {
			width: 300px;
			justify-content: flex-start;
			position: relative;
			height: 174px;
			margin: 0 auto 23px auto;
		}
		&:hover {
			background: #fafafa;
			box-shadow: 0 8px 53px 1px rgba(189, 36, 100, 0.11);
		}
		&:before {
			content: '';
			display: block;
			margin-right: 17px;
			background-image: url(../images/sprite.png);
			@media (max-width: 767px) {
				position: absolute;
				left: 30px;
			}
		}
		&.textile:before {
			background-position: 0px -45px;
		  width: 49px;
			min-width: 49px;
		  height: 36px;
		}
		&.technology:before {
			background-position: -102px 0px;
		  width: 38px;
			min-width: 38px;
		  height: 38px;
		}
		&.guarantees:before {
			background-position: -102px -40px;
		  width: 37px;
			min-width: 37px;
		  height: 37px;
		}
		&.payment:before {
			background-position: -57px 0px;
		  width: 43px;
			min-width: 43px;
		  height: 43px;
		}
		&.delivery:before {
			background-position: 0px 0px;
		  width: 55px;
			min-width: 55px;
		  height: 43px;
		}
	}
	&__title {
		@media (max-width: 767px) {
			padding-left: 80px;
		}
	}
}












.mission {
	display: flex;
	height: 393px;
	margin-bottom: 32px;
	@media (max-width: 1140px) {
		flex-wrap: wrap;
		height: auto;
		padding: 0 10px;
	}
	&__left {
		width: 50%;
		padding: 10px 50px 10px 64px;
		box-sizing: border-box;
		background-color: #fff;
 		display: flex;
		justify-content: center;
		flex-direction: column;
		@media (max-width: 1140px) {
			width: 100%;
			height: 213px;
			padding: 10px;
			text-align: center;
		}
		@media (max-width: 767px) {
			height: 307px;
		}
	}
	&__right {
		width: 50%;
		background-image: url(/images/mission.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding: 40px 48px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@media (max-width: 1140px) {
			width: 100%;
			height: 247px;
			align-items: center;
			padding: 0 20px;
		}
		@media (max-width: 767px) {
			display: block;
			height: auto;
			padding-top: 45px;
			padding-bottom: 45px;
		}
	}
	&__title {
		font-size: 36px;
		font-weight: 600;
		margin-top: 0;
		margin-bottom: 56px;
		text-transform: uppercase;
		@media (max-width: 1140px) {
			margin-bottom: 29px;
		}
		@media (max-width: 767px) {
			font-size: 30px;
		}
	}
	&__description {
		font-size: 16px;
		@media (max-width: 1024px) {
			width: 60%;
			margin: 0 auto;
		}
		@media (max-width: 767px) {
			width: 90%;
		}
	}
	&__block {
		background-color: rgba(250, 250, 250, 0.8);
		background-image: url(/images/mission-block.png);
		background-size: cover;
		width: 235px;
		height: 136px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@media (max-width: 1024px) {
			width: 218px;
			height: 127px;
		}
		@media (max-width: 767px) {
			margin: 0 auto 9px auto;
		}
	}
	&__number {
		font-size: 40px;
		margin-bottom: 10px;
		@media (max-width: 1024px) {
			font-size: 37px;
		}
		sup {
			font-size: 24px;
			@media (max-width: 1024px) {
				font-size: 20px;
			}
		}
	}
}





.production {
	display: flex;
	margin-bottom: 90px;
	@media (max-width: 1024px) {
		padding: 0 10px;
	}
	@media (max-width: 767px) {
		flex-wrap: wrap;
	}
	&__item {
		width: 33%;
		height: 424px;
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
		&:hover {
			.production-hover {
				visibility: visible;
				opacity: 1;
			}
		}
		@media (max-width: 1024px) {
			height: 258px;
		}
		@media (max-width: 767px) {
			width: 100%;
			height: 330px;
		}
	}
	&__img {
		img {
			opacity: 0.7;
			width: 100%;
			@media (max-width: 1200px) {
				width: auto;
			}
			@media (max-width: 767px) {
				width: 100%;
			}
			@media (max-width: 360px) {
				width: auto;
			}
		}
	}
	&__title {
		color: #404040;
		font-size: 30px;
		font-weight: 600;
		z-index: 30;
		position: absolute;
		padding-bottom: 60px;
		padding-left: 57px;
		bottom: 0px;
		left: 0px;
		margin: 0;
		background-image: linear-gradient(to top, #fff, transparent);
		width: 100%;
		height: 80px;
		box-sizing: border-box;
		@media (max-width: 1024px) {
			font-size: 18px;
			padding-bottom: 20px;
			padding-left: 30px;
			height: 60px;
		}
		@media (max-width: 767px) {
			font-size: 23px;
		}
		span {
			width: 60%;
			display: block;
			@media (max-width: 1024px) {
				width: 80%;
			}
		}
	}
}

.production-hover {
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 50;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 44px 83px 44px 53px;
	visibility: hidden;
	opacity: 0;
	transition: all 0.2s ease;
	@media (max-width: 1024px) {
		padding: 30px;
	}
	&__title {
		font-size: 30px;
		margin-bottom: 32px;
		margin-top: 0;
		@media (max-width: 1024px) {
			font-size: 18px;
			margin-bottom: 10px;
		}
		@media (max-width: 767px) {
			font-size: 23px;
		}
	}
	&__text {
		font-size: 14px;
		line-height: 21px;
		@media (max-width: 1024px) {
			font-size: 10px;
			line-height: normal;
		}
		@media (max-width: 767px) {
			font-size: 13px;
		}
	}
	.goods__btn {
		opacity: 1;
		visibility: visible;
	}
}










.brand {
	margin-bottom: 50px;
	@media (max-width: 1024px) {
		padding: 0 10px;
	}
	&__title {
		color: #404040;
		font-size: 36px;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 20px;
		@media (max-width: 1024px) {
			font-size: 30px;
		}
		@media (max-width: 767px) {
			display: none;
		}
	}
	&__list {
		display: flex;
		@media (max-width: 1024px) {
			display: block;
		}
	}
	&__item {
		width: 33%;
		padding: 0 20px;
		box-sizing: border-box;
		@media (max-width: 1024px) {
			width: 100%;
			display: flex;
		}
		@media (max-width: 767px) {
			display: block;
			padding: 0;
		}
	}
	&__logo {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 20px 0;
		height: 130px;
		img {
			border: none;
		}
		@media (max-width: 1024px) {
			margin-right: 30px;
			height: auto;
			width: 220px;
			min-width: 220px;
		}
	}
	&__text {
		padding: 20px 0;
		font-size: 14px;
		line-height: 21px;
	}
}


.news {
	margin-bottom: 70px;
	@media (max-width: 1024px) {
		padding: 0 10px;
	}
	&__list {
		display: flex;
		justify-content: space-between;
		@media (max-width: 767px) {
			flex-wrap: wrap;
		}
	}
	&__title-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 767px) {
			align-items: baseline;
		}
	}
	&__title {
		color: #404040;
		font-size: 36px;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 45px;
		@media (max-width: 1024px) {
			font-size: 29px;
		}
	}
	&__item {
		width: 32%;
		border-bottom: 3px solid #acacac;
		@media (max-width: 1024px) {
			border-bottom: 1px solid #acacac;
		}
		@media (max-width: 767px) {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	&__img {
		height: 158px;
		margin-bottom: 16px;
		overflow: hidden;
		@media (max-width: 1024px) {
			height: 126px;
			margin-bottom: 13px;
		}
		img {
			width: 100%;
			@media (max-width: 1024px) {
				width: auto;
			}
			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}
	&-item__title {
		color: #404040;
		font-size: 20px;
		text-decoration: none;
		margin-bottom: 21px;
		display: block;
		@media (max-width: 1024px) {
			font-size: 13px;
		}
		&:hover {
			color: #bd2464;
		}
	}
}

.date {
	color: #bd2464;
	font-size: 14px;
	margin-bottom: 18px;
	@media (max-width: 1024px) {
		font-size: 11px;
	}
	@media (max-width: 767px) {
		margin-bottom: 13px;
	}
}
