.footer {
  &__title {
    margin-top: auto;
    margin-bottom: 29px;
    font-size: 24px;
    font-weight: 400;
  }
  &__text {
    font-size: 12px;
    line-height: 18px;
  }
}
.footer-top {
  background-color: #333333;
  padding: 40px 0;
  color: #fff;
  @media (max-width: 1024px) {
    padding: 0;
  }
}
.footer-column {
  width: 25%;
  border-right: 1px solid #545454;
  padding: 0 50px;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    width: 50%;
    border: none;
    padding: 35px 50px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 30px 30px;
  }
  &:last-child {
    border: none;
    padding-right: 0;
    @media (max-width: 1024px) {
      padding-right: 50px;
      border-bottom: 1px solid #545454;
      order: -1;
    }
    @media (max-width: 767px) {
      padding-right: 30px;
    }
  }
  &:first-child {
    padding-left: 0;
    @media (max-width: 1024px) {
      padding-left: 50px;
      border-bottom: 1px solid #545454;
      order: -1;
    }
    @media (max-width: 767px) {
      border: none;
      padding-left: 30px;
    }
  }
}
.footer-wrap {
  display: flex;
  padding: 0 10px;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
}
.footer-nav {
  &__link {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    margin-bottom: 15px;
    position: relative;
    &:after {
      content: '';
      width: 7px;
      height: 8px;
      display: block;
      background-image: url(/images/sprite.svg#nav-arrow);
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      color: #bd2464;
      &:after {
        background-image: url(/images/sprite.svg#nav-arrow-pink);
      }
    }

  }
}

.footer-bottom {
  background-color: #1c1c1c;
  height: 67px;
  font-size: 12px;
  color: #979797;
  @media (max-width: 1024px) {
    padding: 0 50px;
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 23px 30px;
  }
  &__wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.social-icon {
  display: flex;
  width: 152px;
  justify-content: space-between;
  @media (max-width: 767px) {
    margin-bottom: 16px;
    order: 3;
  }
  &__link {
    text-decoration: none;
    background-image: url(../images/sprite.png);
    &.inst {
      background-position: -72px -62px;
      width: 18px;
      height: 18px;
    }
    &.vk {
      background-position: -51px -45px;
      width: 25px;
      height: 15px;
    }
    &.fb {
      background-position: -51px -62px;
      width: 19px;
      height: 19px;
    }
  }
}

.copyright {
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}
.dev {
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
  &__link {
    text-decoration: none;
    color: #bd2464;
    &:hover {
      text-decoration: underline;
    }
  }
}
